const masks = {
    creditCard: {creditCard: true},
    cvv: {numeric: true, blocks: [4]},
    decimal: {numeral: true, numeralThousandsGroupStyle: 'thousand'},
    expirationDate: {date: true, delimiter: '/', datePattern: ['m', 'Y']},
    phone: {numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ['(', ') ', '-']},
    zipCode: {
        CA: {uppercase: true, blocks: [3, 3], delimiters: [' ']},
        US: {numericOnly: true, blocks: [5, 4], delimiters: ['-'], delimiterLazyShow: true},
    }
}
